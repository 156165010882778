import { Link } from "react-router-dom";
import Logo from "../../assets/img/black-logo.webp";
const Newsletter = () => {
  return (
    <section className="md:py-[80px] py-[50px]">
      <div className="flex justify-center pb-2">
        <img src={Logo} className="md:w-[150px] w-[120px]" alt="logo" />
      </div>
      <div className="text-center">
        <h1 className="font-RalewayBold md:text-[40px] text-[30px] pb-4">
          Stay Connected With Us
        </h1>
        <p className="md:text-[18px] text-[15px] text-gray-500 font-OpenSansRegular md:px-[30%] veryxs:px-[10%] px-[3%] leading-9">
          Be the first to know about our ministry's activities, upcoming events
          and the amazing things God is doing with and through us. Click the
          button below to subscribe to our newsletter.
        </p>
      </div>
      <div className="md:pt-9 pt-6 flex justify-center w-full font-OpenSansRegular text-[14px]">
        <Link
          target="_blank"
          className="bg-[#FDB53C] rounded-[8px] hover:bg-opacity-80 text-black md:py-5 py-3 md:px-[70px] px-[50px] font-OpenSansSemiBold md:text-[16px] text-[14px] veryxs:max-w-[400px] max-w-[300px] w-full"
          to="http://eepurl.com/iRCj8g"
        >
          <button className="veryxs:max-w-[400px] max-w-[300px] w-full">
            Subscribe
          </button>
        </Link>
      </div>
    </section>
  );
};

export default Newsletter;
