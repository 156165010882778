import Img1 from "../../assets/img/Liberia Crusade 2023/liberia1.webp";
import Img2 from "../../assets/img/Liberia Crusade 2023/liberia2.webp";
import Img3 from "../../assets/img/Liberia Crusade 2023/liberia3.webp";
import Img4 from "../../assets/img/Liberia Crusade 2023/liberia4.webp";
import Img5 from "../../assets/img/Liberia Crusade 2023/liberia5.webp";
import Img6 from "../../assets/img/Liberia Crusade 2023/liberia6.webp";
import Img7 from "../../assets/img/Liberia Crusade 2023/liberia7.webp";
import Img8 from "../../assets/img/Liberia Crusade 2023/liberia8.webp";
import Img9 from "../../assets/img/Liberia Crusade 2023/liberia9.webp";
import Img10 from "../../assets/img/Liberia Crusade 2023/liberia10.webp";
import Img11 from "../../assets/img/Liberia Crusade 2023/liberia11.webp";
import Img12 from "../../assets/img/Liberia Crusade 2023/liberia12.webp";
import Img13 from "../../assets/img/Liberia Crusade 2023/liberia13.webp";
import Img14 from "../../assets/img/Liberia Crusade 2023/liberia14.webp";
import Img15 from "../../assets/img/Liberia Crusade 2023/liberia15.webp";
import Img16 from "../../assets/img/Liberia Crusade 2023/liberia16.webp";
import Img17 from "../../assets/img/Liberia Crusade 2023/liberia17.webp";
import Img18 from "../../assets/img/Liberia Crusade 2023/liberia18.webp";
import Img19 from "../../assets/img/Liberia Crusade 2023/liberia19.webp";
import Img20 from "../../assets/img/Liberia Crusade 2023/liberia20.webp";
import Img21 from "../../assets/img/Liberia Crusade 2023/liberia21.webp";
import Img22 from "../../assets/img/Liberia Crusade 2023/liberia22.webp";
import Img23 from "../../assets/img/Liberia Crusade 2023/liberia23.webp";
import Img24 from "../../assets/img/Liberia Crusade 2023/liberia24.webp";
import Img25 from "../../assets/img/Liberia Crusade 2023/liberia25.webp";
import Img26 from "../../assets/img/Liberia Crusade 2023/liberia26.webp";
import Img27 from "../../assets/img/Liberia Crusade 2023/liberia27.webp";
import Img28 from "../../assets/img/Liberia Crusade 2023/liberia28.webp";
import { useState } from "react";
const LiberiaGallery = () => {
  const imgData = [
    {
      id: 1,
      img: Img1,
    },
    {
      id: 2,
      img: Img2,
    },
    {
      id: 3,
      img: Img3,
    },
    {
      id: 4,
      img: Img4,
    },
    {
      id: 5,
      img: Img5,
    },
    {
      id: 6,
      img: Img6,
    },
    {
      id: 7,
      img: Img7,
    },
    {
      id: 8,
      img: Img8,
    },
    {
      id: 9,
      img: Img9,
    },
    {
      id: 10,
      img: Img10,
    },
    {
      id: 11,
      img: Img11,
    },
    {
      id: 12,
      img: Img12,
    },
    {
      id: 13,
      img: Img13,
    },
    {
      id: 14,
      img: Img14,
    },
    {
      id: 15,
      img: Img15,
    },
    {
      id: 16,
      img: Img16,
    },
    {
      id: 17,
      img: Img17,
    },
    {
      id: 18,
      img: Img18,
    },
    {
      id: 19,
      img: Img19,
    },
    {
      id: 20,
      img: Img20,
    },
    {
      id: 21,
      img: Img21,
    },
    {
      id: 22,
      img: Img22,
    },
    {
      id: 23,
      img: Img23,
    },
    {
      id: 24,
      img: Img24,
    },
    {
      id: 25,
      img: Img25,
    },
    {
      id: 26,
      img: Img26,
    },
    {
      id: 27,
      img: Img27,
    },
    {
      id: 28,
      img: Img28,
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(null);
  const openFullScreen = (index: any) => {
    setSelectedIndex(index);
  };

  const closeFullScreen = () => {
    setSelectedIndex(null);
  };
  return (
    <>
      <div className="px-[3%] pt-10 pb-[60px] text-center">
        <h2 className="text-black text-3xl font-RalewayBold">
          Liberia Crusade 2023 Gallery
        </h2>
        <div className="flex justify-center pt-4">
          <div className="h-2 w-[200px] rounded-full bg-black"></div>
        </div>
      </div>
      <div className="gallery mb-[60px]">
        <div>
          {imgData.map((item, index) => (
            <div
              key={index}
              onClick={() => openFullScreen(index)}
              className="pics"
            >
              <img src={item.img} className="w-full" alt="img" />
            </div>
          ))}
          {selectedIndex !== null && (
            <div className="fixed top-0 left-0 z-50 w-full h-full bg-black flex justify-center items-center">
              <div className="max-w-screen-lg w-full h-full flex justify-center items-center">
                <img
                  src={imgData[selectedIndex].img}
                  alt={`Full Screen ${selectedIndex}`}
                  className="object-contain h-full w-full"
                  onClick={closeFullScreen}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LiberiaGallery;
