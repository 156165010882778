// export const metadata: Metadata = {
//   title: "Contact Us",
//   description:
//     "Get in touch with Us to share praise reports, share informations for new converts, and sharing prayer requests.",

import { useEffect } from "react";
import Hero from "./components/contactus/Hero";
import HeroLink from "./components/contactus/HeroLink";
import PrayerQuote from "./components/contactus/PrayerQuote";

// };
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero />
      <HeroLink />
      <PrayerQuote />
    </>
  );
};

export default ContactUs;
