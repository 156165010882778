import { Link } from "react-router-dom";

const HeroLink = () => {
  return (
    <div>
      <div className="bg-black font-RalewayBold xxs:py-[70px] py-[50px] text-[#FDB53C] grid md:grid-cols-3 grid-cols-1 xxs:grid-cols-2 md:gap-y-10 md:gap-x-[100px] gap-3 sm:gap-5 px-[3%]">
        <Link
          to="partner-with-us"
          className="border-[2px] text-center rounded-[10px] border-[#FDB53C] inline-block xxs:p-5 p-3 hover:text-black hover:bg-[#FDB53C]"
        >
          GIVE NOW
        </Link>
        <Link
          to="/events"
          className="border-[2px] text-center rounded-[10px] border-[#FDB53C] inline-block xxs:p-5 p-3 hover:text-black hover:bg-[#FDB53C]"
        >
          ITINERARY
        </Link>
        <Link
          to="/about"
          className="border-[2px] text-center rounded-[10px] border-[#FDB53C] inline-block xxs:p-5 p-3 hover:text-black hover:bg-[#FDB53C]"
        >
          ABOUT EBMI
        </Link>
        <Link
          to="/contact-us"
          className="border-[2px] text-center rounded-[10px] border-[#FDB53C] inline-block xxs:p-5 p-3 hover:text-black hover:bg-[#FDB53C]"
        >
          CONTACT US
        </Link>
        <Link
          to="/contact-us"
          className="border-[2px] text-center rounded-[10px] border-[#FDB53C] inline-block xxs:p-5 p-3 hover:text-black hover:bg-[#FDB53C]"
        >
          PRAYER REQUESTS
        </Link>
        <Link
          to="/testimonies"
          className="border-[2px] text-center rounded-[10px] border-[#FDB53C] inline-block xxs:p-5 p-3 hover:text-black hover:bg-[#FDB53C]"
        >
          TESTIMONIES
        </Link>
      </div>
    </div>
  );
};

export default HeroLink;
