import { useState, useRef, useEffect } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import Video1 from "../../assets/img/MissionsToIndia2016/vid1.mp4";
import Video2 from "../../assets/img/MissionsToIndia2016/vid2.mp4";
import Video3 from "../../assets/img/MissionsToIndia2016/vid3.mp4";
import Video4 from "../../assets/img/MissionsToIndia2016/vid4.mp4";
import Img1 from "../../assets/img/MissionsToIndia2016/india1.webp";
import Img2 from "../../assets/img/MissionsToIndia2016/india1.webp";
import Img3 from "../../assets/img/MissionsToIndia2016/india1.webp";
import Img4 from "../../assets/img/MissionsToIndia2016/india1.webp";
const India2016Gallery = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const videos = [
    {
      title: "ALL ROUND TOUCH OF THE HOLYGHOST IN INDIA",
      src: Video1,
      image: Img1,
      width: 640,
      height: 360,
    },
    {
      title: "MANY RECEIVED JESUS CHRIST AS THEIR LORD AND SAVIOUR",
      src: Video2,
      image: Img2,
      width: 640,
      height: 360,
    },
    {
      title: "MINISTERING TO THE BELOVED OF GOD",
      src: Video3,
      image: Img3,
      width: 640,
      height: 360,
    },
    {
      title: "THE TRUE NATURE OF GOD IS GOODNESS IN CHRIST JESUS",
      src: Video4,
      image: Img4,
      width: 640,
      height: 360,
    },
  ];

  const openModal = (src: string, title: string) => {
    setVideoSrc(src);
    setVideoTitle(title);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setVideoSrc("");
    setVideoTitle("");
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (isModalOpen && videoSrc && videoElement) {
      videoElement.play();
    } else if (videoElement) {
      videoElement.pause();
    }
  }, [isModalOpen, videoSrc]);

  return (
    <>
      <div className="px-[3%] pt-10 pb-[60px] text-center">
        <h2 className="text-black text-3xl font-RalewayBold">
          Missions To India 2016 Gallery
        </h2>
        <div className="flex justify-center pt-4">
          <div className="h-2 w-[200px] rounded-full bg-black"></div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center pb-10">
        {videos.map((video, index) => (
          <div key={index} className="relative w-64 h-36 m-4">
            <img
              src={video.image}
              width={video.width}
              height={video.height}
              alt={video.title}
              className="object-cover w-full h-full rounded"
            />
            <button
              onClick={() => openModal(video.src, video.title)}
              className="absolute inset-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50 play-button"
            >
              <AiFillPlayCircle className="text-[#FDB53C] text-6xl play-icon" />
            </button>
          </div>
        ))}

        {isModalOpen && (
          <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
            aria-labelledby="exampleModalLabel"
            aria-hidden={!isModalOpen}
          >
            <div className="relative w-full max-w-lg border border-white mx-auto bg-white rounded">
              <div className="modal-header p-4 border-b flex items-center justify-between border-gray-200">
                <h5
                  className="text-xl font-NotoSansBold"
                  id="exampleModalLabel"
                >
                  {videoTitle}
                </h5>
                <button
                  type="button"
                  className="text-black bg-transparent border-none text-3xl leading-none"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body p-4 bg-black">
                <div className="relative" style={{ paddingBottom: "56.25%" }}>
                  <video
                    className="absolute top-0 left-0 w-full h-full"
                    ref={videoRef}
                    controls
                    controlsList="nodownload"
                    src={videoSrc}
                  ></video>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default India2016Gallery;
