import { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import BeninRepublicEvent from "../../assets/img/event1.webp";
import BeninRepublic from "../../assets/img/benin-republic-donate.webp";
import { useNavigate } from "react-router-dom";
const Itinerary = () => {
  const [selectedIndex, setSelectedIndex] = useState(false);
  const router = useNavigate();
  const openFullScreen = () => {
    setSelectedIndex(true);
  };

  const closeFullScreen = () => {
    setSelectedIndex(false);
  };

  const navigatePastEvents = (event: any) => {
    router(`/events/${event}`);
  };

  return (
    <div>
      <div className="bg-black text-white font-RalewayBold xxs:py-[70px] py-[50px] px-[3%]">
        {/* <div className="flex justify-between">
          <div className="minimd:w-auto md:w-[70%]">
            <h1 className="md:text-[25px] sm:text-[23px] text-[21px] pb-3">
              UP NEXT
            </h1>
            <div className="flex gap-7 items-center">
              <div className="bg-white sm:h-[140px] h-[120px] inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
                <div>
                  <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                    AUG
                  </h1>
                  <h1 className="md:text-[35px] sm:text-[33px] text-[31px] leading-8">
                    22
                  </h1>
                  <h1 className="font-RalewayRegular sm:text-[12px] text-[10px]">
                    TO AUG 24
                  </h1>
                </div>
              </div>
              <div>
                <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                  Benin Republic - Gospel Crusade
                </h1>
                <h1 className="font-RalewayRegular pb-2 md:text-[18px] sm:text-[16px] text-[14px]">
                  We are happy to be travelling to Benin republic for this great
                  crusade.
                </h1>
                <button
                  onClick={openFullScreen}
                  className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
                >
                  View Event
                  <FaArrowRightLong size={15} />
                </button>
              </div>
            </div>
          </div>
          <div className="w-[30%]  md:block hidden">
            <img
              src={BeninRepublic}
              className="w-full h-auto"
              alt="ese-beloved"
            />
          </div>
        </div>
        <hr
          className="text-white"
          style={{ marginTop: "40px", marginBottom: "40px" }}
        /> */}
        <div>
          <h1 className="uppercase text-[25px] pb-3">Past Events</h1>
        </div>
        <div>
          <div className="flex gap-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2024
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Benin Republic - Gospel Crusade 2024
              </h1>
              <button
                onClick={() =>
                  navigatePastEvents("benin-republic-crusade-2024")
                }
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2024
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Ghana Crusade 2024
              </h1>
              <button
                onClick={() => navigatePastEvents("ghana-crusade-2024")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2023
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Liberia Crusade 2023
              </h1>
              <button
                onClick={() => navigatePastEvents("liberia-crusade-2023")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2023
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Missions to Nigeria 2023
              </h1>
              <button
                onClick={() => navigatePastEvents("missions-to-nigeria-2023")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2023
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Missions trip to Kenya 2023
              </h1>
              <button
                onClick={() => navigatePastEvents("missions-to-kenya-2023")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2020
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Missions trip to Nigeria 2020
              </h1>
              <button
                onClick={() => navigatePastEvents("missions-to-nigeria-2020")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
          <div className="flex gap-7 pt-7">
            <div className="bg-white inline-flex rounded-[8px] text-center items-center justify-center p-5 text-black">
              <div>
                <h1 className="sm:text-[16px] text-[14px] font-RalewayRegular">
                  2016
                </h1>
              </div>
            </div>
            <div>
              <h1 className="font-RalewayRegular md:text-[30px] sm:text-[28px] text-[26px]">
                Missions to India 2016
              </h1>
              <button
                onClick={() => navigatePastEvents("missions-to-india-2016")}
                className="bg-white mt-4 rounded-[8px] md:text-[16px] sm:text-[14px] text-[12px] text-black flex items-center gap-2 py-3 px-4"
              >
                View Gallery
                <FaArrowRightLong size={15} />
              </button>
            </div>
          </div>
        </div>
        <hr
          className="text-white"
          style={{ marginTop: "40px", marginBottom: "40px" }}
        />
      </div>
      {selectedIndex && (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-black flex justify-center items-center">
          <div className="max-w-screen-lg w-full h-full flex justify-center items-center">
            <img
              src={BeninRepublicEvent}
              alt="event"
              className="object-contain h-full w-full"
              onClick={closeFullScreen}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Itinerary;
