import Logo from "../../assets/img/ministry-logo.webp";
import { ImFacebook } from "react-icons/im";
import { FaYoutube } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { LuMenu } from "react-icons/lu";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { AnimatePresence, MotionConfig, motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
const Navbar = () => {
  const [text, helper] = useTypewriter({
    words: [
      "ESEBELOVED MINISTRY INTERNATIONAL",
      "IMPACTING THE WORLD THROUGH THE GOSPEL",
    ],
    loop: true,
  });
  const { pathname } = useLocation();
  const [showNav, setShowNav] = useState(false);
  const toggleBodyScroll = (modalOpen: boolean) => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(showNav);
    return () => {
      toggleBodyScroll(false);
    };
  }, [showNav]);
  const handleClickLink = () => {
    setShowNav(false);
  };
  return (
    <div className="bg-black border-b border-[#FDB53C] py-6 flex items-center fixed z-50 top-0 left-0 right-0 justify-between px-[3%]">
      <Link to="/" className="flex items-center gap-2">
        <img src={Logo} className="md:w-[80px] w-[50px]" alt="logo" />
        <h1 className="text-[rgb(253,181,60)] md:text-[14px] text-[12px] w-[290px] font-RalewaySemiBold border-[2px] p-1 inline-block border-[#FDB53C]">
          {text}
          <span>
            <Cursor />
          </span>
        </h1>
      </Link>
      <div className="xmd:flex hidden items-center gap-2 font-RalewayRegular text-[17px] text-[#FDB53C]">
        <Link
          to="about"
          className={`${
            pathname === "/about"
              ? "border-b-2 border-[#FDB53C]"
              : "hover:border-white hover:font-RalewaySemiBold hover:border-b-2 hover:text-white"
          } py-2 `}
        >
          About
        </Link>
        <Link
          to="/events"
          className={`${
            pathname.startsWith("/events")
              ? "border-b-2 border-[#FDB53C]"
              : "hover:border-white hover:font-RalewaySemiBold hover:border-b-2 hover:text-white"
          } py-2 `}
        >
          Events
        </Link>
        <Link
          to="/contact-us"
          className={`${
            pathname === "/contact-us"
              ? "border-b-2 border-[#FDB53C]"
              : "hover:border-white hover:font-RalewaySemiBold hover:border-b-2 hover:text-white"
          } py-2 `}
        >
          Contact Us
        </Link>
      </div>
      <div className="hidden xlg:flex items-center gap-3 font-RalewayRegular text-[17px] text-[#FDB53C]">
        <Link
          to="https://www.facebook.com/EseMorganfestus"
          className="hover:text-white"
          target="_blank"
        >
          <ImFacebook size={25} />
        </Link>
        <Link
          to="https://www.youtube.com/@esebeloved"
          target="_blank"
          className="hover:text-white"
        >
          <FaYoutube size={25} />
        </Link>
        <Link
          to="https://www.instagram.com/esebelovedministry?igsh=MTFiYXZ5eWl5bmpyMQ%3D%3D&utm_source=qr"
          target="_blank"
          className="hover:text-white"
        >
          <FaInstagram size={25} />
        </Link>
      </div>
      <div className="xmd:flex hidden items-center gap-10">
        <Link
          className="font-RalewayMedium rounded-[10px] bg-[#FDB53C] py-5 px-10 tracking-wide"
          to="partner-with-us"
        >
          BECOME A PARTNER
        </Link>
      </div>
      <div
        onClick={() => setShowNav(true)}
        className="xmd:hidden block text-[#FDB53C] hover:text-white"
      >
        <LuMenu size={40} />
      </div>
      <AnimatePresence>
        {showNav && (
          <MotionConfig
            transition={{
              type: "spring",
              bounce: 0.25,
            }}
          >
            <motion.div
              key="mobile-nav"
              variants={{
                open: {
                  x: "0%",
                  transition: {
                    when: "beforeChildren",
                    type: "spring",
                    bounce: 0.25,
                  },
                },
                closed: {
                  x: "100%",
                  transition: {
                    when: "afterChildren",
                    type: "spring",
                    bounce: 0.25,
                  },
                },
              }}
              initial="closed"
              animate="open"
              exit="closed"
              className="fixed inset-0 z-[60] bg-opacity-50 backdrop-blur-md flex justify-center items-center"
            >
              <div className="bg-gray-200 absolute right-0 h-screen w-full max-w-[400px]">
                <motion.div
                  variants={{
                    open: { y: "0%", opacity: 1 },
                    closed: { y: "25%", opacity: 0 },
                  }}
                  className="flex justify-end p-4"
                >
                  <IoClose size={25} onClick={() => setShowNav(false)} />
                </motion.div>
                <motion.div
                  variants={{
                    open: { y: "0%", opacity: 1 },
                    closed: { y: "25%", opacity: 0 },
                  }}
                  className=" pl-[8%] pt-10"
                >
                  <div className="text-black flex flex-col">
                    <Link
                      to="about"
                      onClick={handleClickLink}
                      className="font-RalewaySemiBold hover:text-[#FDB53C] py-2"
                    >
                      About
                    </Link>
                    <Link
                      to="/events"
                      onClick={handleClickLink}
                      className="font-RalewaySemiBold hover:text-[#FDB53C] py-2"
                    >
                      Events
                    </Link>
                    <Link
                      to="/contact-us"
                      onClick={handleClickLink}
                      className="font-RalewaySemiBold hover:text-[#FDB53C] py-2"
                    >
                      Contact Us
                    </Link>
                  </div>
                  <motion.div
                    variants={{
                      open: { y: "0%", opacity: 1 },
                      closed: { y: "25%", opacity: 0 },
                    }}
                    className="text-black flex items-center gap-3 pt-4"
                  >
                    <Link
                      to="https://www.facebook.com/EseMorganfestus"
                      onClick={handleClickLink}
                      className="hover:text-[#FDB53C]"
                      target="_blank"
                    >
                      <ImFacebook size={25} />
                    </Link>
                    <Link
                      to="https://www.youtube.com/@esebeloved"
                      target="_blank"
                      onClick={handleClickLink}
                      className="hover:text-[#FDB53C]"
                    >
                      <FaYoutube size={25} />
                    </Link>
                    <Link
                      to="https://www.instagram.com/esebelovedministry?igsh=MTFiYXZ5eWl5bmpyMQ%3D%3D&utm_source=qr"
                      target="_blank"
                      onClick={handleClickLink}
                      className="hover:text-[#FDB53C]"
                    >
                      <FaInstagram size={25} />
                    </Link>
                  </motion.div>
                </motion.div>
              </div>
            </motion.div>
          </MotionConfig>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navbar;
