// export const metadata: Metadata = {
//   title: "About Us",
//   description:
//     "Meet Ese Beloved, a devout disciple of Jesus Christ, whose unwavering dedication to spreading the gospel has led countless individuals to the path of righteousness. Her remarkable journey, marked by unrelenting passion and divine encounters, has transformed lives worldwide.",

import { useEffect } from "react";
import Gallery from "./components/aboutus/Gallery";
import Hero from "./components/aboutus/Hero";
import MainPage from "./components/aboutus/MainPage";

// };
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero />
      <MainPage />
      <Gallery />
    </>
  );
};

export default AboutUs;
