import { Link } from "react-router-dom";
import LiveStream from "../../assets/img/live.webp";
const YoutubeNotice = () => {
  return (
    <div className="relative sm:h-[500px] h-[400px] w-full">
      <div
        style={{
          backgroundImage: `url(${LiveStream})`,
          height: "100%",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="relative"
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="relative z-10 text-white px-[5%] flex items-center h-full">
          <div>
            <h1 className="md:text-[5.25em] uppercase veryxs:text-[2.75em] text-[2em] xxs:text-[3.375em] font-NotoSansBold">
              Our YouTube Videos
            </h1>
            <p className="md:text-[22px] sm:text-[18px] text-[16px] font-OpenSansRegular sm:w-[70%] w-[90%] sm:leading-10 leading-8">
              Click on the Button below to watch our latest content directly on
              our Channel!
            </p>
            <div className="sm:mt-[30px] mt-[10px]">
              <Link to="https://www.youtube.com/@esebeloved" target="_blank">
                <button className="md:text-[18px] text-[15px] rounded-[10px] hover:bg-opacity-90 bg-white text-black font-OpenSansSemiBold md:py-5 py-3 max-w-[400px] w-full">
                  WATCH NOW
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YoutubeNotice;
