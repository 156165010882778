import Img1 from "../../assets/img/Ghana Crusade 2024/ghana1.webp";
import Img2 from "../../assets/img/Ghana Crusade 2024/ghana2.webp";
import Img3 from "../../assets/img/Ghana Crusade 2024/ghana3.webp";
import Img4 from "../../assets/img/Ghana Crusade 2024/ghana4.webp";
import Img5 from "../../assets/img/Ghana Crusade 2024/ghana5.webp";
import Img6 from "../../assets/img/Ghana Crusade 2024/ghana6.webp";
import Img7 from "../../assets/img/Ghana Crusade 2024/ghana7.webp";
import Img8 from "../../assets/img/Ghana Crusade 2024/ghana8.webp";
import Img9 from "../../assets/img/Ghana Crusade 2024/ghana9.webp";
import Img10 from "../../assets/img/Ghana Crusade 2024/ghana10.webp";
import Img11 from "../../assets/img/Ghana Crusade 2024/ghana11.webp";
import Img12 from "../../assets/img/Ghana Crusade 2024/ghana12.webp";
import Img13 from "../../assets/img/Ghana Crusade 2024/ghana13.webp";
import Img14 from "../../assets/img/Ghana Crusade 2024/ghana14.webp";
import Img15 from "../../assets/img/Ghana Crusade 2024/ghana15.webp";
import Img16 from "../../assets/img/Ghana Crusade 2024/ghana16.webp";
import { useState } from "react";
const GhanaGallery = () => {
  const imgData = [
    {
      id: 1,
      img: Img1,
    },
    {
      id: 2,
      img: Img2,
    },
    {
      id: 3,
      img: Img3,
    },
    {
      id: 4,
      img: Img4,
    },
    {
      id: 5,
      img: Img5,
    },
    {
      id: 6,
      img: Img6,
    },
    {
      id: 7,
      img: Img7,
    },
    {
      id: 8,
      img: Img8,
    },
    {
      id: 9,
      img: Img9,
    },
    {
      id: 10,
      img: Img10,
    },
    {
      id: 11,
      img: Img11,
    },
    {
      id: 12,
      img: Img12,
    },
    {
      id: 13,
      img: Img13,
    },
    {
      id: 14,
      img: Img14,
    },
    {
      id: 15,
      img: Img15,
    },
    {
      id: 16,
      img: Img16,
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(null);
  const openFullScreen = (index: any) => {
    setSelectedIndex(index);
  };

  const closeFullScreen = () => {
    setSelectedIndex(null);
  };
  return (
    <>
      <div className="px-[3%] pt-10 pb-[60px] text-center">
        <h2 className="text-black sm:text-3xl text-[26px] font-RalewayBold">
          Ghana Crusade 2024 Gallery
        </h2>
        <div className="flex justify-center pt-4">
          <div className="h-2 sm:w-[200px] w-[150px] rounded-full bg-black"></div>
        </div>
      </div>
      <div className="gallery mb-[60px]">
        <div>
          {imgData.map((item, index) => (
            <div
              key={index}
              onClick={() => openFullScreen(index)}
              className="pics"
            >
              <img src={item.img} className="w-full" alt="img" />
            </div>
          ))}
          {selectedIndex !== null && (
            <div className="fixed top-0 left-0 z-50 w-full h-full bg-black flex justify-center items-center">
              <div className="max-w-screen-lg w-full h-full flex justify-center items-center">
                <img
                  src={imgData[selectedIndex].img}
                  alt={`Full Screen ${selectedIndex}`}
                  className="object-contain h-full w-full"
                  onClick={closeFullScreen}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GhanaGallery;
