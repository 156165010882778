// export const metadata: Metadata = {
//   title: "Events",
//   description:
//     "Get more information about Upcoming events in Esebeloved Ministry International.",
// };

import { useEffect } from "react";
import Hero from "./components/events/Hero";
import Itinerary from "./components/events/Itinerary";

const Events = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero />
      <Itinerary />
    </>
  );
};

export default Events;
