import HeroImg from "../../assets/img/heroimg.webp";
import Logo from "../../assets/img/ministry-logo.webp";
import { AnimatePresence, motion } from "framer-motion";
const Hero = () => {
  const textVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  };
  return (
    <div className="relative md:h-[800px] h-[600px] w-full">
      <div
        style={{
          backgroundImage: `url(${HeroImg})`,
          height: "100%",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="relative"
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-white px-[5%] justify-center flex items-center h-full">
          <AnimatePresence>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={textVariants}
            >
              <div className="sm:flex hidden items-center gap-2">
                <img src={Logo} className="w-[120px]" alt="logo" />
                <h1 className="text-[#FDB53C] font-RalewaySemiBold border-[2px] md:text-[50px] text-[40px] p-1 inline-block border-[#FDB53C]">
                  ESEBELOVED MINISTRY INTERNATIONAL
                </h1>
              </div>
              <p className="text-[#FDB53C] uppercase font-RalewaySemiBold text-center md:text-[40px] text-[30px]">
                Impacting the world through Preaching the Gospel
              </p>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Hero;
