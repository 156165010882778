import { useState, useRef, useEffect } from "react";
import VideoCard from "./VideoCard";
import testimony1Video from "./assets/img/testimonies/testimony1.mp4";
import testimony1Image from "./assets/img/testimonies/testimony1.webp";
const Testimonies = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const videos = [
    {
      title: "Heaviness and pain in heart disappears",
      src: testimony1Video,
      image: testimony1Image,
    },
  ];

  const openModal = (src: string, title: string) => {
    setVideoSrc(src);
    setVideoTitle(title);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setVideoSrc("");
    setVideoTitle("");
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (isModalOpen && videoSrc && videoElement) {
      videoElement.play();
    } else if (videoElement) {
      videoElement.pause();
    }
  }, [isModalOpen, videoSrc]);

  return (
    <>
      <div className="px-[3%] pt-[150px] pb-[60px] text-center">
        <h2 className="text-black text-3xl font-RalewayBold">
          Video Testimonies
        </h2>
        <div className="flex justify-center pt-4">
          <div className="h-2 w-[140px] rounded-full bg-black"></div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center pb-10">
        {videos.map((video, index) => (
          <VideoCard
            key={index}
            title={video.title}
            src={video.src}
            image={video.image}
            onClick={openModal}
          />
        ))}

        {isModalOpen && (
          <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
            aria-labelledby="exampleModalLabel"
            aria-hidden={!isModalOpen}
          >
            <div className="relative w-full max-w-lg border border-white mx-auto bg-white rounded">
              <div className="modal-header p-4 border-b flex items-center justify-between border-gray-200">
                <h5
                  className="text-xl uppercase font-NotoSansBold"
                  id="exampleModalLabel"
                >
                  {videoTitle}
                </h5>
                <button
                  type="button"
                  className="text-black bg-transparent border-none text-3xl leading-none"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body p-4 bg-black">
                <div className="relative" style={{ paddingBottom: "56.25%" }}>
                  <video
                    className="absolute top-0 left-0 w-full h-full"
                    ref={videoRef}
                    controls
                    controlsList="nodownload"
                    src={videoSrc}
                  ></video>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Testimonies;
