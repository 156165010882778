import UpperRoomImg from "../../assets/img/upperroom.webp";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
const UpperRoom = () => {
  const textVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeOut" } },
  };
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const currentRef = sectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (observer && currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  return (
    <div
      ref={sectionRef}
      className="flex md:mb-0 mb-8 flex-col md:flex-row md:items-center items-start justify-between"
    >
      <div
        className="hidden md:block w-1/2 bg-cover bg-center"
        style={{
          backgroundImage: `url(${UpperRoomImg})`,
          height: "600px",
        }}
      ></div>
      <AnimatePresence>
        {inView && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={textVariants}
            className="md:w-[47%] w-auto px-[3%]"
          >
            <p className="font-NotoSansMedium md:text-[22px] veryxs:text-[18px] text-[16px] md:leading-10 leading-8">
              Our Six Hours in tongues daily charge offers all believers
              everywhere an opportunity to stay edified even as they grow in
              their relationship with God and impact the world.
            </p>
            <div className="mt-5">
              <Link
                to="https://tinyurl.com/prayWE"
                target="_blank"
                className="md:text-[18px] veryxs:text-[16px] text-[14px] font-NotoSansMedium active:text-black text-[#FDB53C] underline"
              >
                Click here to join us
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UpperRoom;
