import Hero from "./assets/img/Ghana Crusade 2024/ghana.webp";
import { AnimatePresence, motion } from "framer-motion";
import Nigeria2023Gallery from "./components/MissionsGallery/Nigeria2023";
const MissionsToNigeria2023 = () => {
  const textVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  };
  return (
    <>
      <div className="relative h-[700px] w-full">
        <div
          style={{
            backgroundImage: `url(${Hero})`,
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="relative"
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 text-white px-[5%] justify-center flex items-center h-full">
            <AnimatePresence>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={textVariants}
              >
                <h1 className="text-[#FDB53C] font-NotoSansBold md:text-[100px] sm:text-[80px] text-[60px] text-center p-1 inline-block">
                  MISSIONS TO NIGERIA 2023
                </h1>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
      <Nigeria2023Gallery />
    </>
  );
};

export default MissionsToNigeria2023;
