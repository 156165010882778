import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import Navbar from "./components/home/Navbar";
import Footer from "./components/home/Footer";
import AboutUs from "./about";
import Events from "./events";
import ContactUs from "./contact-us";
import Testimonies from "./Testimonies";
import GhanaCrusade from "./ghana-crusade-2024";
import LiberiaCrusade from "./liberia-crusade-2023";
import MissionsToNigeria2023 from "./missions-to-nigeria-2023";
import MissionsToKenya2023 from "./missions-to-kenya-2023";
import MissionsToNigeria2020 from "./missions-to-nigeria-2020";
import MissionsToIndia2016 from "./missions-to-india-2016";
import GiveNow from "./GiveNow";
import BeninRepublicCrusade from "./benin-republic-crusade-2024";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/testimonies" element={<Testimonies />} />
        <Route path="/partner-with-us" element={<GiveNow />} />
        <Route path="/events/ghana-crusade-2024" element={<GhanaCrusade />} />
        <Route
          path="/events/benin-republic-crusade-2024"
          element={<BeninRepublicCrusade />}
        />
        <Route
          path="/events/liberia-crusade-2023"
          element={<LiberiaCrusade />}
        />
        <Route
          path="/events/missions-to-nigeria-2023"
          element={<MissionsToNigeria2023 />}
        />
        <Route
          path="/events/missions-to-kenya-2023"
          element={<MissionsToKenya2023 />}
        />
        <Route
          path="/events/missions-to-nigeria-2020"
          element={<MissionsToNigeria2020 />}
        />
        <Route
          path="/events/missions-to-india-2016"
          element={<MissionsToIndia2016 />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
