import { useState } from "react";
import Img1 from "../../assets/img/gallery/ese.jpeg";
import Img2 from "../../assets/img/gallery/2.jpeg";
import Img3 from "../../assets/img/gallery/india1.jpeg";
import Img4 from "../../assets/img/gallery/1.jpeg";
import Img5 from "../../assets/img/gallery/pic1.jpeg";
import Img6 from "../../assets/img/gallery/ese3.jpeg";
import Img7 from "../../assets/img/gallery/ese4.jpeg";
import Img8 from "../../assets/img/gallery/ese5.jpeg";
import Img9 from "../../assets/img/gallery/ese6.jpeg";

const Gallery = () => {
  const imgData = [
    { id: 1, img: Img1 },
    { id: 2, img: Img2 },
    { id: 3, img: Img3 },
    { id: 4, img: Img4 },
    { id: 5, img: Img5 },
    { id: 6, img: Img6 },
    { id: 7, img: Img7 },
    { id: 8, img: Img8 },
    { id: 9, img: Img9 },
  ];

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const openFullScreen = (index: number) => {
    setSelectedIndex(index);
  };

  const closeFullScreen = () => {
    setSelectedIndex(null);
  };

  return (
    <div className="gallery mb-[60px]">
      {imgData.map((item, index) => (
        <div
          key={index}
          onClick={() => openFullScreen(index)}
          className="pics cursor-pointer"
        >
          <img
            src={item.img}
            alt={`Gallery ${item.id}`}
            className="w-full object-cover"
          />
        </div>
      ))}
      {selectedIndex !== null && (
        <div
          className="fixed top-0 left-0 z-50 w-full h-full bg-black flex justify-center items-center"
          onClick={closeFullScreen}
        >
          <div className="max-w-screen-lg w-full h-full flex justify-center items-center">
            <img
              src={imgData[selectedIndex].img}
              alt={`Full Screen ${selectedIndex}`}
              className="object-contain h-full w-full"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
