import PartnerImg from "../../assets/img/partner.webp";
import PartnerImgMobile from "../../assets/img/partner 2.webp";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PayPal from "../../assets/img/paypal.webp";
import Monzo from "../../assets/img/monzo.webp";
const Partner = () => {
  const textVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeOut" } },
  };
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const currentRef = sectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (observer && currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className="flex flex-col md:flex-row md:items-center items-start justify-between md:pt-0 veryxs:py-10 py-4"
    >
      <AnimatePresence>
        {inView && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={textVariants}
            className="md:w-[47%] w-auto px-[3%] py-10"
          >
            <h1 className="md:text-[65px] veryxs:text-[45px] text-[40px] font-OpenSansMedium">
              PARTNER
            </h1>
            <h1 className="md:text-[65px] veryxs:text-[45px] text-[40px] font-OpenSansMedium">
              WITH US TODAY
            </h1>
            <p className=" font-NotoSansMedium md:text-[22px] veryxs:text-[18px] text-[16px] md:leading-10 leading-8">
              In few days, we will be in Benin Republic with the good news of
              Jesus Christ. Please help us fulfill this divine mandate by
              partnering with us financially. Your giving will go a long way to
              help us do all that God has laid in our hearts.
            </p>
            <div className="mt-6 inline-flex items-center">
              <Link
                to="partner-with-us"
                className="rounded-[10px] bg-[#FDB53C] sm:py-4 py-3 sm:px-10 px-8 font-NotoSansBold sm:text-[20px] veryxs:text-[18px] text-[16px] text-black"
              >
                Give now
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Desktop Image */}

      <div
        className="hidden md:block w-1/2 bg-cover bg-center"
        style={{
          backgroundImage: `url(${PartnerImg})`,
          height: "600px",
        }}
      ></div>

      {/* Mobile Image */}
      <div className="md:hidden block w-full">
        <img src={PartnerImgMobile} className="w-full" alt="" />
      </div>
    </div>
  );
};

export default Partner;
