import Hero from "./assets/img/ministerese2.webp";
import { AnimatePresence, motion } from "framer-motion";
import India2016Gallery from "./components/MissionsGallery/India2016";
const MissionsToIndia2016 = () => {
  const textVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  };
  return (
    <>
      <div className="relative h-[900px] w-full">
        <div
          style={{
            backgroundImage: `url(${Hero})`,
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="relative"
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 text-white px-[5%] justify-center flex items-center h-full">
            <AnimatePresence>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={textVariants}
              >
                <h1 className="text-[#FDB53C] font-NotoSansBold md:text-[100px] sm:text-[80px] text-[60px] text-center p-1 inline-block">
                  MISSIONS TO INDIA 2016
                </h1>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
      <India2016Gallery />
    </>
  );
};

export default MissionsToIndia2016;
