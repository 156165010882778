import { FaInstagram, FaYoutube } from "react-icons/fa6";
import { ImFacebook } from "react-icons/im";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="text-white bg-black md:py-[100px] py-[60px] pl-[3%]">
      <div className="md:flex block justify-between items-start">
        <div className="md:w-[40%] w-[70%]">
          <h1 className="md:text-[22px] sm:text-[20px] text-[18px] font-NotoSansBold pb-5">
            ESEBELOVED MINISTRY INTERNATIONAL
          </h1>
          <p className="md:text-[17px] sm:text-[15px] text-[13px] font-OpenSansLight leading-9">
            EBMI's mission is to use the preaching of the gospel to make a
            difference in the world. It is an evangelistic ministry founded by
            Sister Ese Beloved, the ministry's goal is to spread the gospel to
            every nation on the planet.
          </p>
        </div>
        <div className="md:w-[20%] w-full md:pt-0 pt-6">
          <h1 className="md:text-[22px] sm:text-[20px] text-[18px] font-NotoSansBold pb-5">
            Contact us
          </h1>
          <ul>
            <li className="md:text-[17px] sm:text-[15px] text-[13px] font-OpenSansLight leading-9">
              info@esebelovedministry.org
            </li>
            <li className="md:text-[17px] sm:text-[15px] text-[13px] pb-3 font-OpenSansLight leading-9">
              Tel: +44 7851 231409
            </li>
          </ul>
        </div>
        <div className="w-[25%] flex items-center gap-3 text-[17px] text-[#FDB53C]">
          <Link
            to="https://www.facebook.com/EseMorganfestus"
            className="hover:text-white md:text-[37px] text-[30px]"
            target="_blank"
          >
            <ImFacebook />
          </Link>
          <Link
            to="https://www.youtube.com/@esebeloved"
            target="_blank"
            className="hover:text-white md:text-[37px] text-[30px]"
          >
            <FaYoutube />
          </Link>
          <Link
            to="https://www.instagram.com/esebelovedministry?igsh=MTFiYXZ5eWl5bmpyMQ%3D%3D&utm_source=qr"
            target="_blank"
            className="hover:text-white md:text-[37px] text-[30px]"
          >
            <FaInstagram />
          </Link>
        </div>
      </div>
      <div className="md:text-[17px] sm:text-[15px] text-[13px] font-OpenSansLight pt-[60px] text-center">
        © 2024 EBMI. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
