import { AnimatePresence, motion } from "framer-motion";
import HeroImg from "../../assets/img/heroimg.webp";

const Hero = () => {
  const textVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  };

  return (
    <div className="relative md:h-[700px] h-[500px] w-full">
      <div
        style={{
          backgroundImage: `url(${HeroImg})`,
          height: "100%",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="relative"
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-white px-[5%] justify-center flex items-center h-full">
          <AnimatePresence>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={textVariants}
            >
              <h1 className="text-[#FDB53C] font-NotoSansBold md:text-[100px] sm:text-[80px] text-[60px] text-center p-1 inline-block">
                GET IN TOUCH WITH US
              </h1>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Hero;
