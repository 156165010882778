import { Link } from "react-router-dom";
import HeroImg from "./assets/img/heroimg.webp";
import PayPal from "./assets/img/paypal.webp";
import Monzo from "./assets/img/monzo.webp";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
const GiveNow = () => {
  const textVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="relative md:h-[800px] h-[600px] w-full">
        <div
          style={{
            backgroundImage: `url(${HeroImg})`,
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="relative"
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 text-white px-[5%] justify-center flex items-center h-full">
            <AnimatePresence>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={textVariants}
              >
                <div>
                  <h1 className="text-[#FDB53C] font-NotoSansBold text-center md:text-[100px] sm:text-[80px] text-[60px] p-1 inline-block">
                    PARTNER WITH US TODAY
                  </h1>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
      <div className="">
        <div className="bg-black border-b border-gray-700 font-RalewayBold xxs:py-[70px] text-[#FDB53C]  py-[50px] px-[3%]">
          <h1 className="text-[18px] pb-4">
            Donate to us through the options below:
          </h1>
          <div className="flex justify-center w-full md:gap-y-10 md:gap-x-[100px] gap-3 sm:gap-5">
            <Link
              target="_blank"
              to="https://www.paypal.me/Eseokhuakhua"
              className="border-[2px] w-full text-center rounded-[10px] hover:bg-opacity-50 border-[#FDB53C] inline-flex justify-center xxs:p-3 p-3 hover:text-black bg-[#FDB53C]"
            >
              <img src={PayPal} className="w-[100px]" alt="paypal" />
            </Link>
            <Link
              target="_blank"
              to="https://monzo.me/eseogheneokhuakhua?h=GKEwap"
              className="border-[2px] w-full text-center rounded-[10px] hover:bg-opacity-50 border-[#FDB53C] inline-flex justify-center xxs:p-3 p-3 hover:text-black bg-[#FDB53C]"
            >
              <img src={Monzo} className="w-[35px]" alt="paypal" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiveNow;
