import BuildingFund from "./components/home/BuildingFund";
import Hero from "./components/home/Hero";
import HeroLink from "./components/home/HeroLink";
import Newsletter from "./components/home/Newsletter";
import Partner from "./components/home/Partner";
import UpperRoom from "./components/home/UpperRoom";
import YoutubeNotice from "./components/home/YoutubeNotice";
export default function Home() {
  return (
    <>
      <Hero />
      <HeroLink />
      <Partner />
      <UpperRoom />
      <BuildingFund />
      <YoutubeNotice />
      <Newsletter />
    </>
  );
}
