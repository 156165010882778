import BuildingFundImg from "../../assets/img/ministerese1.webp";
import BuildingFundImg2 from "../../assets/img/ministerese2.webp";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
const BuildingFund = () => {
  const textVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeOut" } },
  };
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const currentRef = sectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (observer && currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  return (
    <div
      ref={sectionRef}
      className="flex md:flex-row flex-col-reverse md:items-center items-start justify-between relative overflow-hidden"
    >
      <AnimatePresence>
        <div className="md:w-[50%] w-full pl-[3%]">
          {inView && (
            <motion.div
              className="md:py-0 py-4"
              initial="hidden"
              animate="visible"
              variants={textVariants}
            >
              <p className="font-NotoSansMedium md:text-[22px] veryxs:text-[18px] text-[16px] md:leading-10 leading-8 md:w-[60%] w-full">
                As a ministry, we are committed to impacting the whole world
                with the gospel of Jesus Christ, taking one nation at a time
                till the whole earth is filled with the knowledge of Jesus
                Christ.
              </p>
            </motion.div>
          )}
        </div>
      </AnimatePresence>
      <div
        className="md:block hidden bg-cover w-[50%]"
        style={{
          backgroundImage: `url(${BuildingFundImg})`,
          height: "800px",
        }}
      ></div>
      <div
        className="md:hidden block bg-cover w-full"
        style={{
          backgroundImage: `url(${BuildingFundImg2})`,
          height: "400px",
        }}
      ></div>
    </div>
  );
};

export default BuildingFund;
