import { useEffect, useState } from "react";
import Logo from "../../assets/img/black-logo.webp";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import axios, { isAxiosError } from "axios";
import { HttpClient } from "../../api/HttpClient";
import { BeatLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const HeroLink = () => {
  const categories = [
    { id: 1, title: "Health and Healing" },
    { id: 2, title: "Financial Provision" },
    { id: 3, title: "Relationships" },
    { id: 4, title: "Guidance and Wisdom" },
    { id: 5, title: "Spiritual Growth" },
    { id: 6, title: "Protection and Safety" },
    { id: 7, title: "Peace of mind" },
    { id: 8, title: "Forgiveness" },
    { id: 9, title: "Employment" },
    { id: 10, title: "Depression" },
    { id: 11, title: "Deliverance" },
    { id: 12, title: "Academic Success" },
    { id: 13, title: "Missions and Evangelism" },
    { id: 14, title: "Addiction" },
    { id: 15, title: "Mental and Emotional Well-being" },
    { id: 16, title: "Thanksgiving" },
    { id: 17, title: "Reconciliation" },
    { id: 18, title: "Others" },
  ];
  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryNames = response.data.map(
          (country: any) => country.name.common
        );
        countryNames.sort((a: any, b: any) => a.localeCompare(b));
        setCountries(countryNames);
      })
      .catch((error) => {
        console.error("Error fetching the countries:", error);
      });
  }, []);
  // PRAYER REQUEST INITIAL STATE

  const [prayerRequestFormData, setPrayerRequestFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    country: "",
    category: "",
    prayerRequest: "",
  });

  // NEW CONVERTS INITIALSTATE
  const [newConvertFormData, setNewConvertFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    country: "",
    homeAddress: "",
  });

  // PRAISE REPORT INITIAL STATE
  const [praiseReportFormData, setPraiseReportFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    country: "",
    homeAddress: "",
    testimony: "",
  });

  // GENERAL AND PRAYER REQUEST BOOLEAN STATE
  const [countries, setCountries] = useState([]);
  const [showCountries, setShowCountries] = useState(false);
  const [isLoadingPrayer, setIsLoadingPrayer] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [prayerModal, setPrayerModal] = useState(false);

  // NEWCONVERT BOOLEAN STATE

  const [isLoadingConvert, setIsLoadingConvert] = useState(false);
  const [newConvertModal, setNewConvertModal] = useState(false);
  const [showConvertCountries, setShowConvertCountries] = useState(false);

  // PRAISE REPORT BOOLEAN STATE

  const [isLoadingPraiseReport, setIsLoadingPraiseReport] = useState(false);
  const [praiseReportModal, setPraiseReportModal] = useState(false);
  const [showPraiseReportCountries, setShowPraiseReportCountries] =
    useState(false);
  const navigate = useNavigate();
  // SUBMIT REQUEST FOR PRAYER REQUEST
  const handleSubmitPrayerRequest = async (e: any) => {
    e.preventDefault();
    const {
      firstname,
      lastname,
      email,
      phone,
      country,
      category,
      prayerRequest,
    } = prayerRequestFormData;
    const prayerForm = new FormData();
    prayerForm.append("firstname", firstname);
    prayerForm.append("lastname", lastname);
    prayerForm.append("email", email);
    prayerForm.append("phone", phone);
    prayerForm.append("country", country);
    prayerForm.append("category", category);
    prayerForm.append("prayerRequest", prayerRequest);
    setIsLoadingPrayer(true);
    try {
      const res = await HttpClient.post("/prayer", prayerForm);
      setIsLoadingPrayer(false);
      setPrayerModal(true);
      toast.success("Prayer Request sent sucessfully!");
      if (res.data.statusCode === 200) {
        navigate("/");
        setPrayerRequestFormData({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          country: "",
          category: "",
          prayerRequest: "",
        });
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error("Something is wrong!");
      } else {
        toast.error("An Unknown Error occurred");
      }
    } finally {
      setIsLoadingPrayer(false);
      setPrayerModal(false);
    }
  };

  // SUBMIT REQUEST FOR NEW CONVERT
  const handleSubmitNewConvert = async (e: any) => {
    e.preventDefault();
    const { firstname, lastname, email, phone, country, homeAddress } =
      newConvertFormData;
    const newConvertForm = new FormData();
    newConvertForm.append("firstname", firstname);
    newConvertForm.append("lastname", lastname);
    newConvertForm.append("email", email);
    newConvertForm.append("phone", phone);
    newConvertForm.append("country", country);
    newConvertForm.append("homeAddress", homeAddress);
    setIsLoadingConvert(true);
    try {
      const res = await HttpClient.post("/new-convert", newConvertForm);
      toast.success("New convert information sent sucessfully!");
      if (res.data.statusCode === 200) {
        navigate("/");
        setNewConvertFormData({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          country: "",
          homeAddress: "",
        });
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error("Something is wrong!");
      } else {
        toast.error("An Unknown Error occurred");
      }
    } finally {
      setIsLoadingConvert(false);
      setNewConvertModal(false);
    }
  };
  // SUBMIT REQUEST FOR PRAISE REPORT
  const handleSubmitPraiseReport = async (e: any) => {
    e.preventDefault();
    const {
      firstname,
      lastname,
      email,
      phone,
      country,
      homeAddress,
      testimony,
    } = praiseReportFormData;
    const newPraiseReportForm = new FormData();
    newPraiseReportForm.append("firstname", firstname);
    newPraiseReportForm.append("lastname", lastname);
    newPraiseReportForm.append("email", email);
    newPraiseReportForm.append("phone", phone);
    newPraiseReportForm.append("country", country);
    newPraiseReportForm.append("homeAddress", homeAddress);
    newPraiseReportForm.append("testimony", testimony);
    setIsLoadingPraiseReport(true);
    try {
      const res = await HttpClient.post("/praise-report", newPraiseReportForm);
      toast.success("Praise Report sent sucessfully!");
      if (res.data.statusCode === 200) {
        navigate("/");
        setPraiseReportFormData({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          country: "",
          homeAddress: "",
          testimony: "",
        });
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error("Something is wrong!");
      } else {
        toast.error("An Unknown Error occurred");
      }
    } finally {
      setIsLoadingPraiseReport(false);
      setPraiseReportModal(false);
    }
  };

  // HANDLE CHANGE FOR PRAYER REQUEST
  const handlePrayerRequestChange = (event: any) => {
    const { name, value } = event.target;
    setPrayerRequestFormData({ ...prayerRequestFormData, [name]: value });
  };

  // HANDLE CHANGE FOR PRAISE REPORT
  const handleConvertChange = (event: any) => {
    const { name, value } = event.target;
    setNewConvertFormData({ ...newConvertFormData, [name]: value });
  };

  // HANDLE CHANGE FOR PRAISE REPORT
  const handlePraiseReportChange = (event: any) => {
    const { name, value } = event.target;
    setPraiseReportFormData({ ...praiseReportFormData, [name]: value });
  };

  // CATEGORY CLICK FOR PRAYER REQUEST
  const handleCategoryClick = (category: string) => {
    setPrayerRequestFormData({
      ...prayerRequestFormData,
      category: category,
    });
    setShowCategories(false);
  };

  // COUNTRY CLICK FOR PRAYER REQUEST
  const handleCountryClick = (country: string) => {
    setPrayerRequestFormData({ ...prayerRequestFormData, country });
    setShowCountries(false);
  };

  // COUNTRY CLICK FOR NEW CONVERT
  const handleConvertCountryClick = (country: string) => {
    setNewConvertFormData({
      ...newConvertFormData,
      country: country,
    });
    setShowConvertCountries(false);
  };

  // COUNTRY CLICK FOR PRAISE REPORT
  const handlePraiseReportCountryClick = (country: string) => {
    setPraiseReportFormData({
      ...praiseReportFormData,
      country: country,
    });
    setShowPraiseReportCountries(false);
  };
  const toggleBodyScroll = (modalOpen: boolean) => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(prayerModal || newConvertModal || praiseReportModal);
    return () => {
      toggleBodyScroll(false);
    };
  }, [prayerModal, newConvertModal, praiseReportModal]);
  return (
    <div className="bg-black xxs:py-[70px] py-[50px]">
      <h1 className="text-[#FDB53C] text-center pb-10 font-NotoSansMedium md:text-[40px] sm:text-[38px] text-[36px]">
        SELECT ONE OF THE OPTIONS BELOW TO GET IN TOUCH WITH US
      </h1>
      <div className="font-RalewayBold flex justify-center md:flex-nowrap flex-wrap md:gap-[100px] gap-y-5 text-[#FDB53C] px-[3%]">
        <button
          onClick={() => setNewConvertModal(true)}
          className="border-[2px] rounded-[10px] w-full border-[#FDB53C] xxs:p-5 p-3 hover:text-black hover:bg-[#FDB53C]"
        >
          NEW CONVERT
        </button>
        <button
          onClick={() => setPrayerModal(true)}
          className="border-[2px] rounded-[10px] w-full border-[#FDB53C] xxs:p-5 p-3 hover:text-black hover:bg-[#FDB53C]"
        >
          PRAYER REQUESTS
        </button>
        <button
          onClick={() => setPraiseReportModal(true)}
          className="border-[2px] rounded-[10px] w-full border-[#FDB53C] xxs:p-5 p-3 hover:text-black hover:bg-[#FDB53C]"
        >
          SHARE PRAISE REPORTS
        </button>
      </div>
      {newConvertModal && (
        <div className="fixed inset-0 z-[60] bg-opacity-50 backdrop-blur-md flex justify-center items-center">
          <div className="bg-white px-6 py-8 rounded-lg relative w-[100vw] overflow-y-scroll h-[100vh] mx-4">
            <button
              className="absolute top-3 right-3 text-black"
              onClick={() => setNewConvertModal(false)}
            >
              <IoCloseOutline size={30} />
            </button>
            <div className="flex justify-center pb-2">
              <img src={Logo} className="md:w-[150px] w-[120px]" alt="logo" />
            </div>
            <h2 className="text-center md:text-[30px] sm:text-[25px] text-[23px] font-NotoSansMedium">
              NEW CONVERTS
            </h2>
            <h1 className="text-center font-OpenSansRegular md:text-[15px] sm:text-[14px] text-[12px]">
              Just gave you life to Christ or Want to give your life to Christ?
            </h1>
            <div className="flex justify-center">
              <form className="pt-9 max-w-[600px] flex justify-center w-full font-OpenSansRegular sm:text-[14px] text-[12px]">
                <div
                  className="w-full px-[6%]"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <div className="md:col-span-1 col-span-2">
                    <input
                      className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] focus:border-2 outline-none w-full border-gray-600"
                      type="text"
                      name="firstname"
                      onChange={handleConvertChange}
                      value={newConvertFormData.firstname}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="md:col-span-1 col-span-2">
                    <input
                      className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                      type="text"
                      name="lastname"
                      onChange={handleConvertChange}
                      value={newConvertFormData.lastname}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="col-span-2">
                    <input
                      className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                      type="text"
                      name="email"
                      onChange={handleConvertChange}
                      value={newConvertFormData.email}
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="col-span-2">
                    <input
                      className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                      type="tel"
                      name="phone"
                      onChange={handleConvertChange}
                      value={newConvertFormData.phone}
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="col-span-2 relative">
                    <input
                      className="sm:py-4 sm:px-3 py-3 px-2 rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                      type="text"
                      disabled
                      name="country"
                      onChange={handleConvertChange}
                      value={newConvertFormData.country}
                      placeholder="Country"
                    />
                    <div className="absolute sm:right-2 sm:top-2 top-1 right-1 sm:text-[20px] text-[17px] cursor-pointer p-3">
                      <IoIosArrowDown
                        onClick={() => setShowConvertCountries((prev) => !prev)}
                      />
                    </div>
                    {showConvertCountries && (
                      <div className="bg-white absolute top-14 border border-black rounded-[8px] left-0 shadow-md w-full max-h-[200px] overflow-y-auto z-10">
                        {countries.map((country, index) => (
                          <div
                            className="sm:py-2 sm:px-3 py-1 px-2 border-b border-black cursor-pointer hover:bg-gray-200"
                            key={index}
                            onClick={() => handleConvertCountryClick(country)}
                          >
                            {country}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="col-span-2">
                    <textarea
                      className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] resize-none border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                      placeholder="Home Address"
                      name="homeAddress"
                      onChange={handleConvertChange}
                      value={newConvertFormData.homeAddress}
                      rows={4}
                    />
                  </div>
                  <div className="col-span-2">
                    <button
                      onClick={handleSubmitNewConvert}
                      className="bg-[#FDB53C] rounded-[8px] hover:bg-opacity-80 text-black sm:py-4 py-3 px-[70px] font-OpenSansSemiBold sm:text-[16px] text-[14px] w-full"
                    >
                      {isLoadingConvert ? <BeatLoader /> : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {prayerModal && (
        <div className="fixed inset-0 z-[60] bg-opacity-50 backdrop-blur-md flex justify-center items-center">
          <div className="bg-white px-6 py-8 rounded-lg relative w-[100vw] overflow-y-scroll h-[100vh] mx-4">
            <button
              className="absolute top-3 right-3 text-black"
              onClick={() => setPrayerModal(false)}
            >
              <IoCloseOutline size={30} />
            </button>
            <div className="flex justify-center pb-2">
              <img src={Logo} className="md:w-[150px] w-[120px]" alt="logo" />
            </div>
            <h2 className="text-center md:text-[30px] sm:text-[25px] text-[23px] font-NotoSansMedium">
              PRAYER REQUEST
            </h2>
            <h1 className="text-center font-OpenSansRegular md:text-[15px] sm:text-[13px] text-[12px]">
              Let us pray for you today
            </h1>
            <form className="pt-9 flex justify-center w-full font-OpenSansRegular sm:text-[14px] text-[12px]">
              <div
                className="w-full px-[6%]"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "10px",
                }}
              >
                <div className="md:col-span-1 col-span-2">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] focus:border-2 outline-none w-full border-gray-600"
                    type="text"
                    name="firstname"
                    onChange={handlePrayerRequestChange}
                    value={prayerRequestFormData.firstname}
                    placeholder="First Name"
                  />
                </div>
                <div className="md:col-span-1 col-span-2">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    type="text"
                    name="lastname"
                    onChange={handlePrayerRequestChange}
                    value={prayerRequestFormData.lastname}
                    placeholder="Last Name"
                  />
                </div>
                <div className="col-span-2">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    type="text"
                    name="email"
                    onChange={handlePrayerRequestChange}
                    value={prayerRequestFormData.email}
                    placeholder="Email Address"
                  />
                </div>
                <div className="col-span-2">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    type="tel"
                    name="phone"
                    onChange={handlePrayerRequestChange}
                    value={prayerRequestFormData.phone}
                    placeholder="Phone Number"
                  />
                </div>
                <div className="col-span-2 relative">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    type="text"
                    disabled
                    name="country"
                    onChange={handlePrayerRequestChange}
                    value={prayerRequestFormData.country}
                    placeholder="Country"
                  />
                  <div className="absolute sm:right-2 sm:top-2 top-1 right-1 sm:text-[20px] text-[17px] cursor-pointer p-3">
                    <IoIosArrowDown
                      onClick={() => setShowCountries((prev) => !prev)}
                    />
                  </div>
                  {showCountries && (
                    <div className="bg-white absolute top-14 border border-black rounded-[8px] left-0 shadow-md w-full max-h-[200px] overflow-y-auto z-10">
                      {countries.map((country, index) => (
                        <div
                          className="sm:py-2 sm:px-3 py-1 px-2 border-b border-black cursor-pointer hover:bg-gray-200"
                          key={index}
                          onClick={() => handleCountryClick(country)}
                        >
                          {country}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="col-span-2 relative">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    type="text"
                    disabled
                    name="category"
                    onChange={handlePrayerRequestChange}
                    value={prayerRequestFormData.category}
                    placeholder="Category"
                  />
                  <div className="absolute sm:right-2 sm:top-2 top-1 right-1 sm:text-[20px] text-[17px] cursor-pointer p-3">
                    <IoIosArrowDown
                      onClick={() => setShowCategories((prev) => !prev)}
                    />
                  </div>
                  {showCategories && (
                    <div className="bg-white absolute top-14 border border-black rounded-[8px] left-0 shadow-md w-full max-h-[200px] overflow-y-auto z-10">
                      {categories.map((category: any) => (
                        <div
                          className="sm:py-2 sm:px-3 py-1 px-2 border-b border-black cursor-pointer hover:bg-gray-200"
                          key={category.id}
                          onClick={() => handleCategoryClick(category.title)}
                        >
                          {category.title}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-span-2">
                  <textarea
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] resize-none border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    placeholder="Prayer Request"
                    name="prayerRequest"
                    onChange={handlePrayerRequestChange}
                    value={prayerRequestFormData.prayerRequest}
                    rows={6}
                  />
                </div>
                <div className="col-span-2">
                  <button
                    onClick={handleSubmitPrayerRequest}
                    className="bg-[#FDB53C] rounded-[8px] hover:bg-opacity-80 text-black sm:py-4 py-3 px-[70px] font-OpenSansSemiBold sm:text-[16px] text-[14px] w-full"
                  >
                    {isLoadingPrayer ? <BeatLoader /> : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {praiseReportModal && (
        <div className="fixed inset-0 z-[60] bg-opacity-50 backdrop-blur-md flex justify-center items-center">
          <div className="bg-white px-6 py-8 rounded-lg relative w-[100vw] overflow-y-scroll h-[100vh] mx-4">
            <button
              className="absolute top-3 right-3 text-black"
              onClick={() => setPraiseReportModal(false)}
            >
              <IoCloseOutline size={30} />
            </button>
            <div className="flex justify-center pb-2">
              <img src={Logo} className="md:w-[150px] w-[120px]" alt="logo" />
            </div>
            <h2 className="text-center md:text-[30px] sm:text-[25px] text-[23px] font-NotoSansMedium">
              PRAISE REPORT
            </h2>
            <h1 className="text-center font-OpenSansRegular md:text-[15px] sm:text-[13px] text-[12px]">
              Please Share your praise report with us
            </h1>
            <form className="pt-9 flex justify-center w-full font-OpenSansRegular sm:text-[14px] text-[12px]">
              <div
                className="w-full px-[6%]"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "10px",
                }}
              >
                <div className="md:col-span-1 col-span-2">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] focus:border-2 outline-none w-full border-gray-600"
                    type="text"
                    name="firstname"
                    onChange={handlePraiseReportChange}
                    value={praiseReportFormData.firstname}
                    placeholder="First Name"
                  />
                </div>
                <div className="md:col-span-1 col-span-2">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    type="text"
                    name="lastname"
                    onChange={handlePraiseReportChange}
                    value={praiseReportFormData.lastname}
                    placeholder="Last Name"
                  />
                </div>
                <div className="col-span-2">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    type="text"
                    name="email"
                    onChange={handlePraiseReportChange}
                    value={praiseReportFormData.email}
                    placeholder="Email Address"
                  />
                </div>
                <div className="col-span-2">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    type="tel"
                    name="phone"
                    onChange={handlePraiseReportChange}
                    value={praiseReportFormData.phone}
                    placeholder="Phone Number"
                  />
                </div>
                <div className="col-span-2 relative">
                  <input
                    className="sm:py-4 sm:px-3 py-3 px-2 rounded-[8px] border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    type="text"
                    disabled
                    name="country"
                    onChange={handlePraiseReportChange}
                    value={praiseReportFormData.country}
                    placeholder="Country"
                  />
                  <div className="absolute sm:right-2 sm:top-2 top-1 right-1 sm:text-[20px] text-[17px] cursor-pointer p-3">
                    <IoIosArrowDown
                      onClick={() =>
                        setShowPraiseReportCountries((prev) => !prev)
                      }
                    />
                  </div>
                  {showPraiseReportCountries && (
                    <div className="bg-white absolute top-14 border border-black rounded-[8px] left-0 shadow-md w-full max-h-[200px] overflow-y-auto z-10">
                      {countries.map((country, index) => (
                        <div
                          className="sm:py-2 sm:px-3 py-1 px-2 border-b border-black cursor-pointer hover:bg-gray-200"
                          key={index}
                          onClick={() =>
                            handlePraiseReportCountryClick(country)
                          }
                        >
                          {country}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-span-2">
                  <textarea
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] resize-none border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    placeholder="Home Address"
                    name="homeAddress"
                    onChange={handlePraiseReportChange}
                    value={praiseReportFormData.homeAddress}
                    rows={4}
                  />
                </div>
                <div className="col-span-2">
                  <textarea
                    className="sm:py-4 sm:px-3 py-3 px-2 selection:bg-[#FDB53C] rounded-[8px] resize-none border-[1.5px] outline-none w-full border-gray-700 focus:border-2"
                    placeholder="Testimony"
                    name="testimony"
                    onChange={handlePraiseReportChange}
                    value={praiseReportFormData.testimony}
                    rows={6}
                  />
                </div>
                <div className="col-span-2">
                  <button
                    onClick={handleSubmitPraiseReport}
                    className="bg-[#FDB53C] rounded-[8px] hover:bg-opacity-80 text-black sm:py-4 py-3 px-[70px] font-OpenSansSemiBold sm:text-[16px] text-[14px] w-full"
                  >
                    {isLoadingPraiseReport ? <BeatLoader /> : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default HeroLink;
