import Prayer from "../../assets/img/gallery/eseprayer.jpeg";
const PrayerQuote = () => {
  return (
    <div className="flex justify-center">
      <img className="w-[80%]" src={Prayer} alt="prayerimg" />
    </div>
  );
};

export default PrayerQuote;
