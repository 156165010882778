import AboutUsImg from "../../assets/img/esebeloved.webp";
import LandScapeAbout from "../../assets/img/esebelovedd.webp";
import { Link } from "react-router-dom";

const MainPage = () => {
  return (
    <div className="py-[60px] px-[3%]">
      <div className="flex flex-col-reverse xxmd:flex-row justify-between">
        <div className="xxmd:w-[60%] w-full">
          <h1 className="font-OpenSansSemiBold md:text-[35px] sm:text-[33px] text-[30px] pb-6">
            EseBeloved Ministry International
          </h1>
          <div className="font-RalewayRegular md:text-[17px] sm:text-[15px] text-[13px]">
            <div className="sm:pb-4 pb-3">
              <p>
                Meet Ese Beloved, a devout disciple of Jesus Christ, whose
                unwavering dedication to spreading the gospel has led countless
                individuals to the path of righteousness. Her remarkable
                journey, marked by unrelenting passion and divine encounters,
                has transformed lives worldwide.
              </p>
            </div>
            <div className="pb-4">
              <p>
                In 2023, Ese Beloved Ministry was established, solidifying her
                mission to orchestrate global crusades and reach the lost at all
                costs. Her personal testimony of radical redemption from a life
                of sin has fuelled her relentless drive to share the
                transformative power of the gospel.
              </p>
            </div>
            <div className="pb-4">
              <p>
                Ese firmly believes in the power of the gospel and its ability
                to save anyone, regardless of their race, background, or
                socio-economic status. She is committed to sharing the gospel
                with a wide range of audiences through various means and
                platforms, including crusades, conferences, retreats, street
                evangelism, social media, and personal interactions, with
                remarkable signs following, as the Scriptures say.
              </p>
            </div>
            <div className="pb-4">
              <p>
                This has created a ripple effect of hope and redemption,
                inspiring many to embrace a life of faith in Jesus Christ. A
                pivotal moment in her spiritual journey occurred in September
                2022, when she received a profound instruction from the Holy
                Spirit to dedicate six hours daily to praying in the Spirit.
                This encounter birthed the{" "}
                <Link
                  target="_blank"
                  className="hover:text-[#FDB53C] hover:underline"
                  to="https://tinyurl.com/prayWE"
                  rel="noopener noreferrer"
                >
                  Upper Room Experience
                </Link>
                , which she later opened to the public.
              </p>
            </div>
            <div className="pb-4">
              <p>
                Prayer remains the bedrock of Ese Beloved Ministry, and she
                invites everyone to join her in this daily spiritual discipline.
                Learn more about the Upper Room Experience{" "}
                <Link
                  className="hover:text-[#FDB53C] hover:underline"
                  target="_blank"
                  to="https://tinyurl.com/prayWE"
                  rel="noopener noreferrer"
                >
                  here.
                </Link>
                {"  "}Besides her evangelistic endeavors, Ese Beloved is a
                loving wife to Ademola Israel and a devoted mother to their
                three beautiful children.
              </p>
            </div>
            <div className="pb-4">
              <p>
                Join Ese Beloved in her mission to spread the gospel and
                transform lives. Together, let's share the love of Christ with a
                world in need!
              </p>
            </div>
          </div>
        </div>
        <div className="w-[30%] xxmd:block hidden">
          <img src={AboutUsImg} className="w-full h-auto" alt="ese-beloved" />
        </div>
        <div className="xxmd:hidden block w-full">
          <img
            src={LandScapeAbout}
            className="w-full pb-5 h-auto"
            alt="ese-beloved"
          />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
